@tailwind base;
@tailwind components;
@tailwind utilities;

.reactEasyCrop_CropAreaRound {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
}

@layer base {
  tbody {
    display: block;
    max-height: 550px;
    overflow: auto;
  }

  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  tbody::-webkit-scrollbar {
    @apply w-[14px] bg-theme-border/20;
  }

  tbody::-webkit-scrollbar-thumb {
    @apply w-[14px] bg-theme-secondary;
  }

  h1,
  .h1 {
    @apply font-sora text-[25px] font-bold tablet:text-[48px] md:text-[52px] lg:text-[54px];
    line-height: 1.25;
  }

  h2,
  .h2 {
    @apply font-sora text-[28px] tablet:text-[40px] font-bold lg:text-[48px];
    line-height: 1.25;
  }

  h3,
  .h3 {
    @apply font-sora text-[25px] font-bold tablet:text-[32px] md:text-[36px] lg:text-[40px];
    line-height: 1.25;
  }

  h4,
  .h4 {
    @apply font-sora text-[21px] font-bold tablet:text-[25px] md:text-[28px] lg:text-[32px];
    line-height: 1.25;
  }

  h5,
  .h5 {
    @apply font-sora  font-bold text-[19px];
    line-height: 1.25;
  }

  h6 {
    @apply font-sora text-[18px] font-bold;
  }

  body {
    background: linear-gradient(to right, #060714, #0d0f2b);
  }
}

@layer components {
  .center {
    @apply flex justify-center items-center;
  }

  .a-center {
    @apply absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2;
  }
  .ax-center {
    @apply absolute  left-1/2 -translate-x-1/2;
  }
  .ay-center {
    @apply absolute top-1/2 -translate-y-1/2;
  }

  .btn {
    @apply lg:px-12 px-8 py-[10px] font-bold text-[18px] capitalize rounded-2xl flex-shrink-0;
  }

  .gradient_btn {
    background: linear-gradient(104.42deg, #00ffb4 0%, #0f2fff 60.23%);
    box-shadow: inset -6px -6px 6px rgb(0 0 0 / 10%),
      inset 6px 6px 6px rgb(255 255 255 / 10%);
  }

  .gradient-main {
    background: linear-gradient(104.42deg, #00ffb4 0%, #0f2fff 60.23%);
  }

  .btn-primary {
    @apply px-8 lg:py-3 py-2 text-[16px] font-medium rounded-lg w-fit;
  }

  .input-field {
    @apply h-[54px] border border-theme-border rounded-lg px-4 py-2 text-[16px] w-full;
  }

  select,
  input {
    @apply bg-transparent;
  }

  input:not([type="checkbox"], [type="radio"]),
  select {
    @apply h-[54px] border border-theme-border rounded-lg px-4 py-2 text-[16px] w-full;
  }
  input[type="checkbox"]:checked {
    @apply bg-theme-primary;
  }
  input[type="checkbox"]:checked:after {
    content: "\2713";
  }
  input[type="checkbox"] {
    @apply bg-theme-primary cursor-pointer border border-theme-border rounded-sm text-white appearance-none text-center align-middle;
  }

  select option {
    @apply bg-theme-primary;
  }

  .heading-gradient {
    background: linear-gradient(
      177deg,
      rgba(101, 255, 209, 0.979167) 21.61%,
      #4754ff 63%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .text-gradient {
    background: linear-gradient(123.17deg, #00ffb4 0%, #0f2fff 112.86%),
      linear-gradient(
        180deg,
        #8991ff 0%,
        #3a48fd 99.99%,
        rgba(67, 80, 255, 0) 100%
      );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .text-gradient2 {
    background: linear-gradient(
      180deg,
      #bbc0ff 0%,
      #3a48fd 99.99%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .header {
    background-image: url(/public/images/comingsoon/headerbg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .sport {
    background-image: url(/public/images/comingsoon/sportbg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .select-image-label input:checked ~ div {
    border: 2px solid white;
  }

  .bg-blur {
    background: rgba(22, 2, 51, 0.37);
    border-radius: 25px;
  }

  .nav-tabs .nav-link {
    color: white;
  }

  .nav-tabs .nav-link.active {
    color: white;
    border-color: transparent;
    background-color: #0f2fff;
  }

  .exploreimg {
    background-image: url(../public/images/exploreimg.png);
  }
}
